import React from 'react';

const FileUploadForm = ({
    styles,
    handleChange,
    number,
    values,
    truncateString,
    screen,
    ...props
}) => {
    const file = values['file' + number];
    const limit = screen === 'xs' ? 17 : screen === 'xl' ? 60 : 33;

    return (
        <div className={styles.files}>
            <label htmlFor={'files_' + number}>
                <div className={styles.uploadBtn}>
                    {screen !== 'xs' ? 'Choose file' : 'Upload'}
                </div>
                <input
                    type="file"
                    id={'files_' + number}
                    name={'files_' + number}
                    onChange={handleChange}
                    multiple
                />
            </label>

            <div
                className={`${styles.fileName} ${
                    file === 'No files chosen' ? styles.noFile : null
                }`}
            >
                {truncateString(file, limit)}
            </div>
        </div>
    );
};

export default FileUploadForm;
