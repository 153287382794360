import React, { useState } from 'react';
import * as styles from './ApplicationFaq.module.scss';
import { FaRegQuestionCircle } from '@react-icons/all-files/fa/FaRegQuestionCircle';
import { IoTriangle } from '@react-icons/all-files/io5/IoTriangle';

const ApplicationFaq = () => {
    const [faqPressed, setFaqPressed] = useState([false, false, false, false]);

    const changeFaq = (btn) => {
        var newFaq = [...faqPressed];
        newFaq[btn] = !newFaq[btn];
        setFaqPressed(newFaq);
    };

    const getFaqs = () => {
        return [
            {
                id: 0,
                question: 'Can I apply if I am not a STEM student?',
                answer:
                    'We believe that students from all disciplines are capable of being a good software engineer. However, according to our previous fellowships, STEM fellows have had a higher completion rate than non-STEM fellows. With that being said, we will still accept non-STEM students as long as you are able to show us your initiatives in programming or related fields.'
            },
            {
                id: 1,
                question:
                    'I currently have a full-time commitment, can I still participate in this programme?',
                answer:
                    'While we think it is best if you’re able to participate in our programmes on a full-time basis, we understand this might not be possible for some people. Therefore, part-time courses are available for those that are under extenuating circumstances. Please contact us at shawnyip@digitalsocietyfund.org to allow your case to be assessed.'
            },
            {
                id: 2,
                question: 'Who are your hiring partners?',
                answer:
                    'We are constantly expanding our network with new hiring partners joining us every day. To name a few, reputable companies like Samsung, Unilever...etc. are all within our network.'
            },
            {
                id: 3,
                question:
                    'Do the career programmes offer visa sponsorship for placement?',
                answer:
                    'We require participants to have the proper work authorization to work in respective countries.'
            },
            {
                id: 4,
                question: 'Do I need to pay for the scholarship?',
                answer:
                    'No! This is a fully funded programme. You will not need to pay a single cent unless you decide to quit your sponsored course or career placement before completion.'
            },
            {
                id: 5,
                question:
                    'Can I apply for the Grace Hopper scholarshp programme if I am not a female?',
                answer:
                    'We have two professional certifications scholarship programmes running at the moment - Grace Hopper Scholarship & Neumann Fellowship. The Grace Hopper programme is designed specifically for female candidates only whereas the fellowship is for STEM students. If you’re interested in joining us but are not eligible under these two programmes, please send us an email, your application will be reviewed on a case-by-case basis.'
            },
            {
                id: 6,
                question: 'I still have more questions.',
                answer:
                    'Please email your question at shawnyip@digitalsocietyfund.org.'
            }
        ];
    };

    return (
        <div className={styles.applicationFaqContainer}>
            <div className={styles.faqTitle}>
                <FaRegQuestionCircle className={styles.icon} />
                <h5>FAQs</h5>
            </div>

            {getFaqs().map((faq) => {
                return (
                    <div
                        className={
                            faqPressed[faq.id] === true
                                ? styles.faqItemOpen
                                : styles.faqItemClose
                        }
                        onClick={() => changeFaq(faq.id)}
                    >
                        <div className={styles.title}>
                            <IoTriangle className={styles.icon} />
                            <h6>{faq.question}</h6>
                        </div>
                        <div className={styles.answer}>{faq.answer}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default ApplicationFaq;
