import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import * as styles from './application.module.scss';
import ApplicationForm from '../components/ApplicationForm';
import ApplicationFaq from '../components/ApplicationFaq';
import { useForm, ValidationError } from '@formspree/react';
import ThankYou from '../components/ThankYou';
import { graphql } from 'gatsby';
import { FiAlertCircle } from '@react-icons/all-files/fi/FiAlertCircle';

const Application = ({ data }) => {
    const [state, handleSubmit] = useForm(process.env.GATSBY_APPLICATION_FORM);

    const scholarship = data.scholarshipsJson;

    if (state.succeeded) {
        return (
            <Layout darkBg={false}>
                <ThankYou type="application" />
            </Layout>
        );
    }

    return (
        <Layout darkBg={false}>
            <div className={styles.applicationContainer}>
                <div className={styles.completeApplication}>
                    <h1>Your application</h1>

                    <ApplicationForm
                        handleSubmit={handleSubmit}
                        programme={scholarship}
                        state={state}
                    />

                    {state.errors.map((error) => {
                        return (
                            <div>
                                <span>
                                    {error.field} - {error.code} {error.message}
                                </span>
                            </div>
                        );
                    })}
                </div>

                <div className={styles.faqContainer}>
                    <ApplicationFaq />
                </div>
            </div>
        </Layout>
    );
};

export default Application;

export const scholarshipsApplicationQuery = graphql`
    query($slug: String!) {
        scholarshipsJson(slug: { eq: $slug }) {
            name
            courses {
                title
            }
            details {
                deadline
            }
        }
    }
`;
