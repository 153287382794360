import React from 'react';
import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown';

const EducationForm = ({
    number,
    sections,
    styles,
    edustyles,
    total,
    screen,
    ...props
}) => {
    const getEducationLevel = () => {
        return [
            'Doctoral Degree',
            "Master's Degree",
            'Graduate Certificate',
            "Bachelor's Degree",
            'Associate Degree',
            'Undergraduate Certificate',
            'Diploma',
            'Other'
        ];
    };

    return (
        <div
            className={`${styles.content} ${sections[1] ? null : styles.hide}`}
        >
            <div
                className={`${
                    screen === 'large' || 'small' ? null : styles.divide2
                }`}
            >
                <div className={styles.selection}>
                    <div className={styles.background}></div>
                    <div className={styles.arrow}>
                        <FaChevronDown />
                    </div>

                    <label
                        htmlFor={'edu_' + number + '_level'}
                        className={styles.label}
                    >
                        ({number}) Education level
                    </label>

                    <select
                        name={'edu_' + number + '_level'}
                        id={'edu_' + number + '_level'}
                        className={`${styles.option} ${
                            props.values['eduLevel' + number] === ''
                                ? styles.unselect
                                : styles.selected
                        }`}
                        placeholder="Eg: Postgraduate"
                        onChange={props.handleChange}
                        value={props.values['eduLevel' + number]}
                    >
                        <option value="" disabled defaultValue hidden>
                            Select your option
                        </option>
                        {getEducationLevel().map((level) => {
                            return <option>{level}</option>;
                        })}
                    </select>
                </div>

                <div className={styles.input}>
                    <label
                        htmlFor={'edu_' + number + '_university'}
                        className={styles.label}
                    >
                        University / Awarding Institution
                    </label>
                    <input
                        type="text"
                        id={'edu_' + number + '_university'}
                        name={'edu_' + number + '_university'}
                        className={styles.field}
                        placeholder="Eg: University of London"
                    />
                </div>
            </div>

            <div className={styles.divide2}>
                <div className={styles.input}>
                    <label
                        htmlFor={'edu_' + number + '_major'}
                        className={styles.label}
                    >
                        Major
                    </label>
                    <input
                        type="text"
                        id={'edu_' + number + '_major'}
                        name={'edu_' + number + '_major'}
                        className={styles.field}
                        placeholder="Eg: Computer Science"
                    />
                </div>

                <div className={styles.input}>
                    <label
                        htmlFor={'edu_' + number + '_year'}
                        className={styles.label}
                    >
                        Year of graduation
                    </label>
                    <input
                        type="number"
                        id={'edu_' + number + '_year'}
                        name={'edu_' + number + '_year'}
                        placeholder="Eg: 2020"
                        className={styles.field}
                    />
                </div>
            </div>

            <div className={styles.divide2}>
                <div className={styles.input}>
                    <label
                        htmlFor={'edu_' + number + '_result'}
                        className={styles.label}
                    >
                        Result
                    </label>
                    <input
                        type="text"
                        id={'edu_' + number + '_result'}
                        name={'edu_' + number + '_result'}
                        className={styles.field}
                        placeholder="Eg: CGPA 3.7"
                    />
                </div>
            </div>

            <div
                className={`${edustyles.formDivider} ${
                    total.length === number ? styles.hide : null
                }`}
            ></div>
        </div>
    );
};

export default EducationForm;
