import React, { useState, useEffect } from 'react';
import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown';
import { FiMinusCircle } from '@react-icons/all-files/fi/FiMinusCircle';
import { FiPlusCircle } from '@react-icons/all-files/fi/FiPlusCircle';
import { RiDeleteBin6Line } from '@react-icons/all-files/ri/RiDeleteBin6Line';
import { RiInformationLine } from '@react-icons/all-files/ri/RiInformationLine';
import { ImCheckmark } from '@react-icons/all-files/im/ImCheckmark';
import EducationForm from './EducationForm';
import Loading from './Loading';
import * as edustyles from './EducationForm.module.scss';
import * as styles from './ProfessionalForm.module.scss';

import FileUploadForm from './FileUploadForm';

const ApplicationForm = ({ programme, handleSubmit, state, ...props }) => {
    const [sections, setSections] = useState([true, true, true, true]);
    const [education, setEducation] = useState([1]);
    const [files, setFiles] = useState([1, 2, 3]);
    const [screen, setScreen] = useState(null);
    const [hasRan, setHasRan] = useState(false);
    const [didRecaptcha, setDidRecaptcha] = useState(false);
    const [values, setValues] = useState({
        programme: programme.name,
        programme_course: '',
        eduLevel1: '',
        file1: 'No files chosen',
        file2: 'No files chosen',
        file3: 'No files chosen',
        consent1: true,
        consent2: true,
        consent3: true
    });

    const getCourses = () => {
        let courses = programme.courses.map((course) => course.title);
        return courses.map((title) =>
            title.replace('Certified Associate in ', '')
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name.includes('files_')) {
            let newName = 'file' + name.replace('files_', '');
            let newValue = e.target.files[0].name;
            let fakePath = 'C:\\fakepath\\';

            setValues({
                ...values,
                [newName]: newValue.includes(fakePath)
                    ? newValue.replace(fakePath, '')
                    : newValue
            });
        } else if (name.includes('_level')) {
            let newName =
                'eduLevel' + name.replace('edu_', '').replace('_level', '');
            setValues({
                ...values,
                [newName]: value
            });
        } else if (name.includes('consent')) {
            let newName = name.replace('consent_', 'consent');

            setValues({
                ...values,
                [newName]: e.target.checked
            });
        } else {
            setValues({
                ...values,
                [name]: value
            });
        }
    };

    const addEducationForm = () => {
        let arr = [...education];
        arr.push(arr.length + 1);
        setEducation(arr);

        let newVal = { ...values };
        let newItem = 'eduLevel' + arr.length;
        setValues({
            [newItem]: '',
            ...newVal
        });
    };

    const removeEducationForm = () => {
        if (education.length !== 1) {
            let arr = [...education];
            arr.pop();
            setEducation(arr);

            let number = arr.length + 1;
            let item = `eduLevel${number}`;
            let newVal = { ...values };
            delete newVal[item];
            setValues(newVal);
        }
    };

    const addFileForm = () => {
        let arr = [...files];
        arr.push(arr.length + 1);
        setFiles(arr);

        let newVal = { ...values };
        let newItem = 'file' + arr.length;
        setValues({
            [newItem]: 'No files chosen',
            ...newVal
        });
    };

    const removeFileForm = () => {
        if (files.length !== 1) {
            let arr = [...files];
            arr.pop();
            setFiles(arr);

            let number = arr.length + 1;
            let item = `file${number}`;
            let newVal = { ...values };
            delete newVal[item];
            setValues(newVal);
        }
    };

    const handleSection = (number) => {
        let arr = [...sections];
        arr[number] = !arr[number];
        setSections(arr);
    };

    const getFilesInstruction = () => {
        return [
            {
                number: 1,
                instruction: 'CV or resume'
            },
            {
                number: 2,
                instruction: 'Cover letter'
            },
            {
                number: 3,
                instruction: 'Academic transcripts'
            },
            {
                number: 4,
                instruction: 'Other relevant documents (if any)'
            }
        ];
    };

    const getConsents = () => {
        return [
            {
                number: 1,
                title:
                    'I release to DSF and the course provider the right to access all my current and ongoing, personal and academic records, and transcripts.'
            },
            {
                number: 2,
                title:
                    'If awarded a Fellowship, I understand that I must meet the Fellowship criteria and Standards of Academic Progress for the the programme. If I have chosen or been forced to drop out, I may be required to partially (or fully) repay the tuition fees of the course.'
            },
            {
                number: 3,
                title:
                    'I certify that the statements herein are true to the best of my knowledge and grant my permission for the information contained herein to be shared with the Fellowship selection committee(s).'
            }
        ];
    };

    const truncateString = (str, num) => {
        if (num < str.length) {
            let slicedStr = str.slice(0, num);
            return slicedStr + '...';
        } else {
            return str;
        }
    };

    const handleRecaptcha = () => {
        setDidRecaptcha(true);
    };

    const updateScreenSize = () => {
        setScreen(getScreenSize());
    };

    const getScreenSize = () => {
        const width = window.innerWidth;

        if (width >= 1300) {
            return 'xl';
        } else if (width >= 992 && width < 1300) {
            return 'large';
        } else if (width >= 600 && width < 992) {
            return 'small';
        } else {
            return 'xs';
        }
    };

    const handleResize = () => {
        setScreen(getScreenSize());
    };

    useEffect(() => {
        if (!hasRan) {
            setHasRan(true);
            updateScreenSize();
            if (values['programme_course'] === '') {
                let course = localStorage.getItem('chosenCourse');
                setValues({
                    ...values,
                    ['programme_course']:
                        typeof course !== 'string' ? '' : course
                });
            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [getScreenSize, handleResize]);

    return (
        <div className={styles.professionalFormMainContainer}>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div
                    className={`${styles.section} ${
                        sections[0] ? null : styles.closeSection
                    }`}
                >
                    <h5 onClick={() => handleSection(0)}>
                        PERSONAL DETAILS{' '}
                        {sections[0] ? (
                            <FiMinusCircle className={styles.icon} />
                        ) : (
                            <FiPlusCircle className={styles.icon} />
                        )}
                    </h5>

                    <div
                        className={`${styles.content} ${
                            sections[0] ? null : styles.hide
                        }`}
                    >
                        <div className={styles.divide2}>
                            <div className={styles.input}>
                                <label htmlFor="name" className={styles.label}>
                                    Full name
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className={styles.field}
                                />
                            </div>

                            <div className={styles.input}>
                                <label
                                    htmlFor="details_nationality"
                                    className={styles.label}
                                >
                                    Nationality
                                </label>
                                <input
                                    type="text"
                                    id="details_nationality"
                                    name="details_nationality"
                                    className={styles.field}
                                />
                            </div>
                        </div>

                        <div className={styles.divide2}>
                            <div className={styles.input}>
                                <label
                                    htmlFor="details_phone"
                                    className={styles.label}
                                >
                                    Contact number
                                </label>
                                <input
                                    type="number"
                                    id="details_phone"
                                    name="details_phone"
                                    className={styles.field}
                                />
                            </div>

                            <div className={styles.input}>
                                <label htmlFor="email" className={styles.label}>
                                    Email address
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className={styles.field}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`${styles.section} ${
                        sections[1] ? null : styles.closeSection
                    }`}
                >
                    <h5 onClick={() => handleSection(1)}>
                        EDUCATION
                        {sections[1] ? (
                            <FiMinusCircle className={styles.icon} />
                        ) : (
                            <FiPlusCircle className={styles.icon} />
                        )}
                    </h5>

                    {education.map((item) => {
                        return (
                            <EducationForm
                                sections={sections}
                                number={item}
                                styles={styles}
                                edustyles={edustyles}
                                total={education}
                                handleChange={handleChange}
                                values={values}
                                screen={screen}
                            />
                        );
                    })}

                    <div
                        className={`${edustyles.btnContainer} ${
                            sections[1] ? null : styles.hide
                        }`}
                    >
                        <div
                            onClick={() => removeEducationForm()}
                            className={`${edustyles.removeBtn} ${
                                education.length === 1 ? edustyles.hide : null
                            }`}
                        >
                            <RiDeleteBin6Line className={edustyles.icon} />
                            Remove last
                        </div>

                        <div
                            onClick={() => addEducationForm()}
                            className={edustyles.mainBtn}
                        >
                            Add more
                        </div>
                    </div>
                </div>

                <div
                    className={`${styles.section} ${
                        sections[2] ? null : styles.closeSection
                    }`}
                >
                    <h5 onClick={() => handleSection(2)}>
                        FELLOWSHIP INFORMATION
                        {sections[2] ? (
                            <FiMinusCircle className={styles.icon} />
                        ) : (
                            <FiPlusCircle className={styles.icon} />
                        )}
                    </h5>

                    <div className={`${styles.input} ${styles.hide}`}>
                        <label
                            htmlFor="programme_scholarship"
                            className={styles.label}
                        >
                            Scholarship
                        </label>

                        <input
                            type="text"
                            id="programme_scholarship"
                            name="programme_scholarship"
                            value={values.programme}
                        />
                    </div>

                    <div
                        className={`${styles.content} ${
                            sections[2] ? null : styles.hide
                        }`}
                    >
                        <div className={styles.selection}>
                            <div className={styles.background}></div>
                            <div className={styles.arrow}>
                                <FaChevronDown />
                            </div>

                            <label
                                htmlFor="programme_course"
                                className={styles.label}
                            >
                                Course
                            </label>

                            <select
                                name="programme_course"
                                id="programme_course"
                                className={`${styles.option} ${
                                    values['programme_course'] === ''
                                        ? styles.unselect
                                        : styles.selected
                                }`}
                                onChange={handleChange}
                                value={values['programme_course']}
                            >
                                <option value="" disabled defaultValue hidden>
                                    Select your option:
                                </option>
                                {getCourses().map((level) => {
                                    return <option>{level}</option>;
                                })}
                            </select>
                        </div>

                        <div className={styles.input}>
                            <label
                                htmlFor="programme_visa"
                                className={styles.label}
                            >
                                Work Authorization
                            </label>

                            <input
                                type="text"
                                id="visa"
                                name="programme_visa"
                                className={styles.field}
                                placeholder="Eg: Singapore"
                            />

                            <div className={edustyles.information}>
                                <RiInformationLine className={edustyles.icon} />
                                <span>
                                    Please insert the countries that you are
                                    authorised to work in without a work visa
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`${styles.section} ${
                        sections[3] ? null : styles.closeSection
                    }`}
                >
                    <h5 onClick={() => handleSection(3)}>
                        SUPPORTING DOCUMENTS
                        {sections[3] ? (
                            <FiMinusCircle className={styles.icon} />
                        ) : (
                            <FiPlusCircle className={styles.icon} />
                        )}
                    </h5>

                    <div
                        className={`${styles.content} ${
                            sections[3] ? null : styles.hide
                        }`}
                    >
                        <div className={styles.label}>Please upload your:</div>

                        <div className={edustyles.instructions}>
                            {getFilesInstruction().map((list) => {
                                return (
                                    <div className={edustyles.list}>
                                        <span>{list.number}.</span>
                                        <p>{list.instruction}</p>
                                    </div>
                                );
                            })}
                        </div>

                        <div className={styles.label}>Upload your files:</div>

                        {files.map((item) => {
                            return (
                                <FileUploadForm
                                    styles={styles}
                                    handleChange={handleChange}
                                    number={item}
                                    values={values}
                                    truncateString={truncateString}
                                    screen={screen}
                                />
                            );
                        })}

                        <div
                            className={`${edustyles.btnContainer} ${
                                edustyles.formMargin
                            } ${sections[3] ? null : styles.hide}`}
                        >
                            <div
                                onClick={() => removeFileForm()}
                                className={`${edustyles.removeBtn} ${
                                    files.length === 1 ? edustyles.hide : null
                                }`}
                            >
                                <RiDeleteBin6Line className={edustyles.icon} />
                                Remove last
                            </div>

                            <div
                                onClick={() => addFileForm()}
                                className={edustyles.mainBtn}
                            >
                                Add more
                            </div>
                        </div>
                    </div>
                </div>

                <div className={edustyles.consentContainer}>
                    {getConsents().map(({ number, title }) => {
                        return (
                            <div className={edustyles.consent}>
                                <input
                                    type="checkbox"
                                    name={'consent_' + number}
                                    value={values['consent' + number]}
                                    onClick={handleChange}
                                    defaultChecked
                                />

                                <div
                                    className={`${edustyles.inputBox} ${
                                        values['consent' + number]
                                            ? edustyles.checked
                                            : null
                                    }`}
                                >
                                    <ImCheckmark />
                                </div>

                                <label
                                    htmlFor={'consent_' + number}
                                    className={edustyles.title}
                                >
                                    {title}
                                </label>
                            </div>
                        );
                    })}
                </div>

                <div className={styles.buttonsWithLoading}>
                    <button
                        type="submit"
                        className={styles.applicationFormButton}
                        disabled={state.submitting}
                    >
                        Submit Application
                    </button>

                    {state.submitting ? (
                        <Loading className={styles.loadingBoxContainer} />
                    ) : null}
                </div>
            </form>
        </div>
    );
};

export default ApplicationForm;
