// extracted by mini-css-extract-plugin
export var formDivider = "EducationForm-module--formDivider--1d-nD";
export var information = "EducationForm-module--information--3a6_x";
export var icon = "EducationForm-module--icon--2ydvl";
export var btnContainer = "EducationForm-module--btnContainer--2JwLu";
export var mainBtn = "EducationForm-module--mainBtn--2r9Dt";
export var removeBtn = "EducationForm-module--removeBtn--3IhZ8";
export var hide = "EducationForm-module--hide--w4_N3";
export var formMargin = "EducationForm-module--formMargin--lZv_W";
export var instructions = "EducationForm-module--instructions--1t2LW";
export var list = "EducationForm-module--list--1uPYd";
export var consentContainer = "EducationForm-module--consentContainer--1iLIw";
export var consent = "EducationForm-module--consent--3fxse";
export var inputBox = "EducationForm-module--inputBox--1R1Y9";
export var checked = "EducationForm-module--checked--2KOpG";
export var title = "EducationForm-module--title--1kFFt";