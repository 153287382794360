import React from 'react';
import * as styles from './Loading.module.scss';

const Loading = () => {
    return (
        <div className={styles.loadingContainer}>
            Loading ...
            <div className={styles.bars}>
                <div className={`${styles.bar} ${styles.bar1}`}> </div>
                <div className={`${styles.bar} ${styles.bar2}`}> </div>
                <div className={`${styles.bar} ${styles.bar3}`}> </div>
                <div className={`${styles.bar} ${styles.bar4}`}> </div>
                <div className={`${styles.bar} ${styles.bar5}`}> </div>
                <div className={`${styles.bar} ${styles.bar6}`}> </div>
                <div className={`${styles.bar} ${styles.bar7}`}> </div>
                <div className={`${styles.bar} ${styles.bar8}`}> </div>
            </div>
        </div>
    );
};

export default Loading;
